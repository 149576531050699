import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import MainFrame from './components/MainFrame/MainFrame.js';


ReactDOM.render(
  <MainFrame />
  , document.getElementById('root')
);

reportWebVitals();
