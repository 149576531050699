import React, {useEffect, useState} from 'react'
import './Header.css';
import recycleBinEmpty from './recycleBinEmpty.png'
import recycleBinFull from './recycleBinFull.png'
import newNote from './newNote.png'


function Header(props) {
  //hooks
  const [recycling, setRecycling] = useState(props.inRecycleBin);
  const [recycleBinImg, setRecycleBinImg] = useState(null);

  useEffect(()=>{
    setRecycling(props.inRecycleBin);
    //console.log(`recycling ${recycling} and prop inRecycleBin ${props.inRecycleBin}`)
  },[recycling,props.inRecycleBin])

  useEffect(()=>{
    props.binFull?
      setRecycleBinImg(recycleBinFull):
      setRecycleBinImg(recycleBinEmpty);
  },[props.binFull, props.toMain])

  //variables and functions


  let recycleBinAlt = props.binFull?
    "there are items in the trash bin":
    "the trash bin is empty"; 
  const handleRecycling = () => {
    !recycling && props.toBin();
    recycling && props.toMain();
    setRecycling(!recycling);
  }
  return (
    <header 
      className= "Header"
      id={recycling? "recycleBin":undefined}
    >
      <div className="headerText">
        <h1>The post it App!</h1>
        <p>A place where you can keep track of your notes :)</p>
      </div>
      <div className="headerButtons">
        <button 
          onClick= {
            !recycling?
            props.newPostIt:
            props.emptyRecycleBin}
        >
          {!recycling && <img
            src={newNote}
            alt="New post-it"
            style={{height: '6rem'}}
          />}
          {recycling && "Empty the Trash Bin"}
        </button>
        <button
          onClick= {handleRecycling}
        >
          {!recycling && <img src={recycleBinImg} alt={recycleBinAlt} />}
          {recycling && "go to main Workspace"}
          
        </button>
      </div>
    </header>
  );
}

export default Header;
