// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PostItList {
    display: flex;
    flex-flow: row-reverse wrap-reverse;
    justify-content: space-evenly;
  }
`, "",{"version":3,"sources":["webpack://./src/components/PostItList/PostItList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mCAAmC;IACnC,6BAA6B;EAC/B","sourcesContent":[".PostItList {\n    display: flex;\n    flex-flow: row-reverse wrap-reverse;\n    justify-content: space-evenly;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
