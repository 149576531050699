import React, {useState, useEffect} from 'react';
import './PostIt.css';
import edit from './edit.png';
import saveAs from './saveAs.png';
import recycleButton from './recycleButton.png';
import changeColor from './changeColor.png';
import restoreImg from './restoreImg.png';

function PostIt(props) {
  //hooks section
  const [editing, setEditing] = useState(false);
  const [paragraph, setParagraph] = useState(props.text);
  const [title, setTitle] = useState(props.title);
  const [recycle, setRecycle] = useState(props.recycle);
  const [color, setColor] = useState(props.color);
  
  useEffect(() => {
    !editing?
    props.editSavedPostIt(props.id, paragraph, title, color):
    console.log(`it's ${editing} that you can edit!`)
    // eslint-disable-next-line
  }, [editing, props.id])

  //variables and functions
  const retrievedDate = new Date(props.date);
  const dateSummary = `Created on ${retrievedDate.toDateString()}`;
  const timeSummary = `at ${retrievedDate.toLocaleTimeString()}`;
  

  function handleRecycleState() {
    recycle === "Sure?" && props.sendToRecycle(props.id);
    recycle === "Restore?" && props.sendToMain(props.id);
    recycle === "Send to recycle?" && setRecycle("Sure?");
    recycle === "Sure?" && setRecycle("Restore?");
    recycle === "Restore?" && setRecycle("Send to recycle?");
  }

  function handleStyle() { 
    switch(color){
      case 'yellow':
        setColor('red');
        break;
      case 'red':
        setColor('green');
        break;
      case 'green':
        setColor('purple');
        break;
      case 'purple':
        setColor('orange');
        break;
      case 'orange':
        setColor('yellow');
        break;
      default:
        setColor('yellow');
    }
  }

  return (
    <div
      className= "PostItNote"
      id = {color}
      style= {recycle === "Restore?"?{
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: 'white'}:{}}

    >
      <div className= "TitleDiv">
        <div className="dateAndButtons">
        <span>{dateSummary} <br/>{timeSummary}</span>
          <button
            onClick={e => setEditing(!editing)}
          >
            {!editing && <img src={edit} alt="Edit..." />}
            {editing && <img src={saveAs} alt="Save?" />}
          </button>
          <button 
            onClick={ e => handleRecycleState()}
            style= {recycle === "Sure?"?{backgroundColor: 'rgba(123, 218, 0, 0.445)'}:{}}

          >
            {recycle === "Send to recycle?" && <img src={recycleButton} alt="Recycle?" />}
            {recycle === "Sure?" && recycle}
            {recycle === "Restore?" && <img src={restoreImg} alt="Restore?" />}
          </button>
          <button
            onClick={editing?e=>handleStyle():undefined}
          >{editing && <img src= {changeColor} alt= "Change Color" />}</button>
        </div>
        <textarea
        placeholder="Title?"
        className= "PostItTitle"
        maxLength= "40"
        value= {title}
        onChange= {e => {
          editing && setTitle(e.target.value);
//          setRecycle("Send to recycle?")
        }}
        style= {recycle === "Restore?"?{
          color: 'white'}:{}
        }
      />
      </div>
      <textarea
        placeholder="In here write whatever you want!"
        className= "PostItParagraph"
        value= {paragraph}
        onChange= { e => {
          editing && setParagraph(e.target.value);
//         setRecycle("Send to recycle?")
        }}
        style= {recycle === "Restore?"?{
          color: 'white'}:{}
        }
      />
    </div>
  );
}

export default PostIt;
