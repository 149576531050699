import React, { useState, useEffect } from "react";
import "./MainFrame.css";
import Header from "../Header/Header";
import PostItList from "../PostItList/PostItList";

function MainFrame(props) {
  useEffect(() => {
    if (!localStorage.getItem("PostIts")) {
      localStorage.setItem("PostIts", "[]");
    }
  }, []);
  //hooks
  const [postItArray, setPostItArray] = useState(
    JSON.parse(localStorage.getItem("PostIts"))
  );
  const [inRecycleBin, setInRecycleBin] = useState(false);
  const [binFull, setBinFull] = useState(false);

  useEffect(() => {
    if (localStorage.length === 0 || localStorage.PostIts === "[]") {
      const Greeting = [
        {
          title: "This is a greeting postit! :)",
          text:
            "Here, you can write whatever you want" +
            "\nClick on edit to edit, then click save to save it :P" +
            "\nTo send to the recycle bin, click on 'send to recycle'" +
            "\nconfirm your desition clicking on 'sure?'",
          key: "0",
          id: "0",
          date: new Date(),
          recycle: "Send to recycle?",
          color: "yellow",
        },
      ];
      localStorage.setItem("PostIts", JSON.stringify(Greeting));
      setPostItArray(Greeting);
    } else setBinFull(postItArray.reduce(reducerBin, false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    postItArray && setBinFull(postItArray.reduce(reducerBin, false));
  }, [postItArray, props.binFull, props.toMain]);

  //variables and functions

  function reducerBin(acc, postit) {
    return acc || postit.recycle === "Restore?";
  }

  function editSavedPostIt(id, paragraph, title, color) {
    let postIts = JSON.parse(localStorage.getItem("PostIts"));
    if (postIts == null)
      return console.error("try again later, problem reaching local storage");
    postIts = postIts.map((postIt) => {
      if (postIt.id === id) {
        postIt.text = paragraph;
        postIt.title = title;
        postIt.color = color;
      }
      return postIt;
    });
    localStorage.setItem("PostIts", JSON.stringify(postIts));
    setPostItArray(postIts);
  }

  function sendToRecycle(id) {
    let postIts = JSON.parse(localStorage.getItem("PostIts"));
    if (postIts == null)
      return console.error("try again later, problem reaching local storage");
    postIts = postIts.map((postIt) => {
      if (postIt.id === id) {
        postIt.recycle = "Restore?";
      }
      return postIt;
    });
    localStorage.setItem("PostIts", JSON.stringify(postIts));
    setPostItArray(postIts);
    setInRecycleBin(true);
    setBinFull(true);
  }

  function sendToMain(id) {
    let postIts = JSON.parse(localStorage.getItem("PostIts"));
    postIts = postIts.map((postIt) => {
      if (postIt.id === id) {
        postIt.recycle = "Send to recycle?";
      }
      return postIt;
    });
    localStorage.setItem("PostIts", JSON.stringify(postIts));
    setPostItArray(postIts);
    setInRecycleBin(false);
  }

  const toBin = function () {
    setInRecycleBin(true);
  };

  const toMain = function () {
    setInRecycleBin(false);
  };

  const newPostIt = function () {
    let date = new Date().toISOString();
    let newPostIt = {
      date: date,
      title: "",
      text: "",
      key: date,
      id: date,
      recycle: "Send to recycle?",
      color: "yellow",
    };
    let newPostItArray = JSON.parse(localStorage.getItem("PostIts"));
    newPostItArray.push(newPostIt);
    localStorage.setItem("PostIts", JSON.stringify(newPostItArray));

    setPostItArray(newPostItArray);
  };

  const emptyRecycleBin = function () {
    let notDiscarded = postItArray;
    notDiscarded = notDiscarded.filter(
      (postIt) => postIt.recycle !== "Restore?"
    );
    localStorage.setItem("PostIts", JSON.stringify(notDiscarded));
    setPostItArray(notDiscarded);
    setBinFull(false);
  };

  return (
    <div className="MainFrame">
      <Header
        binFull={binFull}
        inRecycleBin={inRecycleBin}
        emptyRecycleBin={emptyRecycleBin}
        newPostIt={newPostIt}
        toBin={toBin}
        toMain={toMain}
      />
      <div className="">
        {props.children}
        {postItArray && (
          <PostItList
            sendToMain={sendToMain}
            sendToRecycle={sendToRecycle}
            editSavedPostIt={editSavedPostIt}
            postItArray={postItArray}
            inRecycleBin={inRecycleBin}
          />
        )}
      </div>
    </div>
  );
}

export default MainFrame;
