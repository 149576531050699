import React from 'react';
import './PostItList.css';
import PostIt from '../PostIt/PostIt';


function PostItList(props) {
    if (!props.postItArray) return null;
    let noteState = props.inRecycleBin === true?
     (recycle)=> recycle === "Restore?":
     (recycle)=> recycle !=="Restore?";
    
    return (
        <div className = "PostItList"> {
            props.postItArray.map(postIt => (
                noteState(postIt.recycle)? (
                    <PostIt
                        sendToMain= {props.sendToMain}
                        sendToRecycle= {props.sendToRecycle}
                        editSavedPostIt = {props.editSavedPostIt}
                        text= {postIt.text}
                        title= {postIt.title}
                        key= {postIt.key}
                        id= {postIt.id}
                        date= {postIt.date}
                        recycle= {postIt.recycle}
                        color={postIt.color}
                    />) : false
                ))
            }
        </div>
        )
  }

export default PostItList;
