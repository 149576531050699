// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainFrame {
    display: flex;
    flex-flow: column;
    justify-self: stretch;
    background-color:rgb(244, 238, 255);
    width: 100%;
    height: auto;
    min-height: 100%;
  }

.children {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/MainFrame/MainFrame.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,qBAAqB;IACrB,mCAAmC;IACnC,WAAW;IACX,YAAY;IACZ,gBAAgB;EAClB;;AAEF;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".MainFrame {\n    display: flex;\n    flex-flow: column;\n    justify-self: stretch;\n    background-color:rgb(244, 238, 255);\n    width: 100%;\n    height: auto;\n    min-height: 100%;\n  }\n\n.children {\n  display: flex;\n  flex-flow: row wrap;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
